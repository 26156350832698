import { Input, DatePicker, Select } from 'antd'

const getFieldByType = ({ type, disabled, options }) => {
  if (type === 'date') {
    return <DatePicker style={{ width: '100%' }} disabled={disabled} />
  }
  if (type === 'number') {
    return <Input type="number" disabled={disabled} />
  }
  if (type === 'select') {
    return (
      <Select
        disabled={disabled}
        options={options.map(option => ({
          label: option.label,
          value: option.value,
        }))}
        placeholder="Seleccionar..."
        showSearch
        filterOption={(input, option) =>
          option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      />
    )
  }

  return type === 'string' ? (
    <Input disabled={disabled} />
  ) : (
    <Input disabled={disabled} />
  )
}

export default getFieldByType
