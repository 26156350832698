import { CameraOutlined, DownloadOutlined } from '@ant-design/icons'
import { Button, Card, Flex, Image } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'


import downloadPDF from '../downloadPDF'
import PatientInformationDescriptions from './PatientInformationDescriptions'
import UploadImageModal from '../../../../components/UploadImageModal'

const PatientInformation = ({
  patient,
  physicalActivityLevels,
  communes,
  healthInsurances,
  professions,
}) => {
  const { id } = useParams()
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)

  return (
    <>
      <Card style={{ marginBottom: '6vh' }}>
        <Flex align="top" justify="space-between">
          <h1>Información del paciente</h1>
          <Flex>
            <Image
              width={100}
              src={patient.profilePictureUrl}
            />
            <Flex vertical justify='space-between' style={{ marginLeft: 30 }}>
              <Button
                icon={<CameraOutlined />}
                onClick={() => setOpen(true)}
              >
                Actualizar foto
              </Button>
              <Button
                icon={<DownloadOutlined />}
                loading={loading}
                onClick={async () => {
                  setLoading(true)
                  await downloadPDF(`/patients/${id}/generate-pdf`)
                  setLoading(false)
                }}
              >
                Descargar perfil
              </Button>
            </Flex>
          </Flex>
        </Flex>

        <PatientInformationDescriptions
          patient={patient}
          physicalActivityLevels={physicalActivityLevels}
          communes={communes}
          healthInsurances={healthInsurances}
          professions={professions}
        />
      </Card>
      <UploadImageModal
        open={open}
        setOpen={setOpen}
      />
    </>
  )
}

export default PatientInformation
