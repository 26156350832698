const base64ToFile = (base64String, fileName) => {
  // Extraemos la parte base64 sin el encabezado
  const arr = base64String.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];  // Obtenemos el tipo MIME
  const bstr = atob(arr[1]);  // Decodificamos la base64 a binario
  let n = bstr.length;
  const u8arr = new Uint8Array(n);  // Creamos un array de 8 bits sin signo

  // Llenamos el array con los datos binarios
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  // Creamos un objeto File con el array de datos binarios
  return new File([u8arr], fileName, { type: mime });
};

export default base64ToFile;