import { Button, Modal, notification } from "antd"
import { useEffect, useRef, useState } from "react"
import { useParams } from "react-router-dom"
import axios from "axios"

import base64ToFile from "./base64ToFile"
import apiClient, { handleApiError } from "../../services/apiClient"

const UploadImageModal = ({ open, setOpen }) => {
  const videoRef = useRef(null)
  const streamRef = useRef(null)
  const [photoTaken, setPhotoTaken] = useState(false)
  const [photoData, setPhotoData] = useState(null)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()

  const startCamera = async () => {
    console.log('Starting camera')
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true })
      streamRef.current = stream
      if (videoRef.current) {
        videoRef.current.srcObject = stream
      }
    } catch (err) {
      console.error("Error al acceder a la cámara: ", err)
    }
  }

  const stopCamera = () => {
    console.log('Stopping camera')
    const stream = streamRef.current
    if (stream) {
      stream.getTracks().forEach(track => track.stop())
      streamRef.current = null
    }
  }

  const capturePhoto = () => {
    const video = videoRef.current;

    if (video) {
      // Create a canvas element
      const canvas = document.createElement('canvas')
      canvas.width = 640
      canvas.height = 480

      // Draw the video frame to the canvas
      const context = canvas.getContext('2d')
      context.drawImage(video, 0, 0, canvas.width, canvas.height)

      // Convert the canvas to a data URL in PNG format
      const photo = canvas.toDataURL('image/png')
      setPhotoTaken(true)
      setPhotoData(photo)
    }
  }

  const handleOk = async () => {
    setLoading(true)
    const file = base64ToFile(photoData, 'captured-image.png');

    try {
      const response = await apiClient.post(`/patients/${id}/request-signed-url`, {
        fileName: file.name,
        contentType: file.type,
      })
      const signedUrl = response.data.signedUrl

      const uploadResponse = await axios.put(signedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      })

      if (uploadResponse.status === 200) {
        console.log('File uploaded successfully')
        await apiClient.put(`/patients/${id}`, {
          profilePictureFilename: `patients/${id}/${file.name}`
        })
        console.log('Profile picture filename updated')
        window.location.reload()

      } else {
        notification.error({
          message: 'Error',
          description: 'No se pudo subir el archivo'
        })
        console.error('Error uploading file:', uploadResponse.statusText)
      }

    } catch (error) {
      setLoading(false)
      handleApiError({ error })
      console.error('Error:', error)
    }
  }

  const handleCancel = () => {
    setOpen(false)
    setPhotoTaken(false)
    setPhotoData(null)
  }

  useEffect(() => {
    if (open) {
      startCamera()
    } else {
      stopCamera()
    }

  }, [open])

  return (
    <>
      <Modal title="Subir imagen"
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        cancelText="Cancelar"
        okText="Subir imagen"
        okButtonProps={{
          disabled: !photoTaken,
          loading: loading
        }
        }>
        {photoTaken ? (<>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <img src={photoData} alt="Foto capturada" />
          </div>
        </>) : (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
            <video ref={videoRef} width="640" height="480" autoPlay></video>
            <Button onClick={capturePhoto} block>Tomar foto</Button>
          </div>
        )}
      </Modal>
    </>
  )

}

export default UploadImageModal;