import { Descriptions } from 'antd'
import dayjs from 'dayjs'

const PatientInformationDescriptions = ({ patient, communes, physicalActivityLevels, healthInsurances, professions }) => {
  return (
    <Descriptions title="Detalles" bordered size="default">
      <Descriptions.Item label="ID">{patient.id}</Descriptions.Item>
      <Descriptions.Item label="Nombres">
        {patient.firstName} {patient.secondName}
      </Descriptions.Item>
      <Descriptions.Item label="Apellidos">
        {patient.firstLastName} {patient.secondLastName}
      </Descriptions.Item>
      <Descriptions.Item label="Fecha de Nacimiento">
        {dayjs(patient.dateOfBirth).format('D [de] MMMM [del] YYYY')}
      </Descriptions.Item>
      <Descriptions.Item label="Edad">{patient.age} años</Descriptions.Item>
      <Descriptions.Item label="Género">{patient.sex}</Descriptions.Item>
      <Descriptions.Item label="Nivel de Actividad Física">
        {
          physicalActivityLevels.find(
            level =>
              String(level.id) === String(patient.physicalActivityLevelId)
          )?.level
        }
      </Descriptions.Item>
      <Descriptions.Item label="Comuna">
        {
          communes.find(
            comuna => String(comuna.id) === String(patient.communeId)
          )?.name
        }
      </Descriptions.Item>
      <Descriptions.Item label="Previsión">
        {
          healthInsurances.find(
            insurance =>
              String(insurance.id) === String(patient.healthInsuranceId)
          )?.name
        }
      </Descriptions.Item>
      <Descriptions.Item label="Profesión">
        {
          professions.find(
            profession =>
              String(profession.id) === String(patient.professionId)
          )?.name
        }
      </Descriptions.Item>
      <Descriptions.Item label="Teléfono">
        {patient.phoneNumber}
      </Descriptions.Item>
      <Descriptions.Item label="Correo Electrónico">
        {patient.email}
      </Descriptions.Item>
      <Descriptions.Item label="Dirección">
        {patient.address}
      </Descriptions.Item>
      <Descriptions.Item label="Referido por">
        {patient.referedBy}
      </Descriptions.Item>

      <Descriptions.Item label="RUT">{patient.rut}</Descriptions.Item>
    </Descriptions>
  )
}

export default PatientInformationDescriptions